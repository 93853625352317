import React from "react"
import { Link } from "gatsby"

import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"

import medication from "../../images/4.3.1_medication.jpg"
import dietChange from "../../images/4.3.3_dietchange.jpg"
import activity from "../../images/4.3.2_activity.jpg"

import managingAtHome from "../../images/4.3-managing-at-home.jpg"
import looseStool from "../../images/4.3-loose-stool.jpg"

import fruitsVeggies from "../../images/4.3_fruitsveggies.png"
import drinkFluids from "../../images/4.3_drinkfluids.png"
import mildFoods from "../../images/4.3_mildfoods.png"
import bananas from "../../images/4.3_bananas.png"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const ConstipationDiarrhea = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Constipation and Diarrhea" description="Gastrointestinal side effects, such as constipation and diarrhea, are common side effects of neuroblastoma treatment. Learn what causes these side effects and how you can help manage them." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Constipation and Diarrhea">
        <h1 className="section__heading section__heading--green h1">Constipation <em>and</em> Diarrhea</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p><Link rel="glossary" to="/resources/glossary/#constipation">Constipation</Link> and <Link rel="glossary" to="/resources/glossary/#diarrhea">diarrhea</Link> are common side effects of neuroblastoma treatment. Learn why they happen and how they will be managed.</p>
                <h2 className="tc-barney jost-med h2">Constipation</h2>
                <p>Constipation is when your child’s bowel movements (stools) are hard or difficult to pass.</p>
                <h3 className="tc-viridian h4 jost-med">Potential causes of constipation</h3>
                <p>Constipation can be caused by: </p>
                <div className="row">
                  <div className="columns mt-1">
                    <ul className="ul--dotted">
                      <li>
                        Medications
                        <ul>
                          <li>Certain <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link></li>
                          <li>Strong pain medicines (opioids)</li>
                          <li>Some antinausea medicines</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="columns mt-1">
                    <ul className="ul--dotted">
                      <li>Not drinking enough water</li>
                      <li>Decreased activity</li>
                      <li>Pressure from a tumor</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Signs and symptoms of constipation</h4>
            <p>Your child may go to the bathroom less often or in very small amounts. This can lead to other symptoms like pain, decreased appetite, hemorrhoids, or tears in the skin, which could lead to infections. </p>
            <h4 className="tc-viridian h4 jost-med">Treating constipation </h4>
            <p>Talk to the healthcare team about your child's symptoms of constipation, and they will determine the best treatment for your child. Common treatments for constipation include: </p>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <div className="text-center">
              <img src={medication} style={{maxWidth:`115px`}} alt=""/>
            </div>
            <ul className="ul--dotted lg_mt-1 mt-2">
              <li>
                Medication
                <ul>
                  <li>Stool softeners can increase water and fat in the stool to make it softer, and easier to pass</li>
                  <li>Laxatives can help by either increasing fluid in the gastrointestinal tract or increasing contractions in the muscles of the intestines</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="columns small-12 large-4 mt-2">
            <div className="text-center">
              <img className="lg_mt-2 lg_mb-1" src={dietChange} style={{maxWidth:`146px`}} alt=""/>
            </div>
            <ul className="ul--dotted lg_mt-1 mt-2">
              <li>
                Diet Change
                <ul>
                  <li>Drinking plenty of water</li>
                  <li>Eating foods high in fiber (fruits, vegetables, whole grains)</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="columns small-12 large-4 mt-2">
            <div className="text-center">
              <img src={activity} style={{maxWidth:`162px`}} alt=""/>
            </div>
            <ul className="ul--dotted lg_mt-1 mt-2">
              <li>
                Physical activity
                <ul>
                  <li>Regular activity, such as walking, can help stimulate bowel function</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h4 className="tc-viridian jost-med h4">Managing constipation at home</h4>
            <p>There will be some actions you can take at home to help manage your child’s constipation. Some helpful tips are:</p>
            <ul className="ul--dotted">
              <li>Keeping records of your child’s toileting habits</li>
              <li>Talking to your child about constipation in an age-appropriate way</li>
              <li>Consistently following instructions from your child’s healthcare team</li>
            </ul>
          </div>
          <div className="columns small-12 large-4 text-center large-text-right">
            <FancyImage url={managingAtHome} alt="managing constipation at home"/>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <AlertInfoCard className="lg_mt-2 mt-2" text="Please call your child’s healthcare team immediately if you notice a change in your child’s bowel movements (not as often, not as much), pain when having a bowel movement, or hard stool even after giving medicines for constipation. "/>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="tc-barney h2 jost-med">Diarrhea</h3>
            <p>Diarrhea is frequent watery or loose stools. If your child has diarrhea, they may develop dehydration or electrolyte imbalances. It will be important to make sure your child is drinking enough fluids so they do not become dehydrated. In some severe cases, children may need IV fluids to replace the fluids lost from diarrhea.</p>
          </div>
          <div className="columns large-4 small-12 text-center large-text-left">
            <FancyImage url={looseStool} alt='diarrhea'/>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-4 mt-4">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Potential causes of diarrhea</h4>
            <p>Many factors may cause diarrhea, such as:</p>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <div className="row">
              <div className="columns mt-1">
                <ul className="ul--dotted">
                  <li><Link rel="glossary" to="/resources/glossary/#chemotherapy">Chemotherapy</Link></li>
                  <li>Radiation</li>
                  <li><Link rel="glossary" to="/resources/glossary/#antibody-therapy">Antibody therapy</Link></li>
                  <li>Antibiotics</li>
                </ul>
              </div>
              <div className="columns mt-1">
                <ul className="ul--dotted">
                  <li>Infection (virus or bacteria)</li>
                  <li>Bone marrow transplant</li>
                  <li>Stress and anxiety</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Signs and symptoms of diarrhea</h4>
            <p>Diarrhea may cause cramping or <Link rel="glossary" to="/resources/glossary/#incontinence">incontinence</Link> (unable to make it to the toilet in time). It may also lead to skin irritation or breakdown, which may lead to infections.</p>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 mt-2">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Treating diarrhea</h4>
            <p>Treatment for diarrhea can vary depending on the cause. Medications can be given to help control diarrhea, but they may not always be recommended. You should always talk to your child’s healthcare team before giving your child any antidiarrhea medications.</p>
            <ul className="ul--dotted">
              <li>If diarrhea is caused by an infection, treatment with antibiotics may be necessary</li>
              <li>If diarrhea is caused by chemotherapy, the dosing or frequency may be changed</li>
            </ul>
            <p>The healthcare team may also recommend changes in diet to help manage your child’s diarrhea. You can help your child by making sure they:</p>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4 mb-4">
          <div className="columns">
            <Slider className="grid-slider" {...settings}>
              <div className="text-center">
                <img src={drinkFluids} style={{maxWidth:`100px`}} alt=""/>
                <p className="mt-2">Drink plenty of fluids</p>
              </div>
              <div className="text-center">
                <img src={mildFoods} style={{maxWidth:`130px`}} alt=""/>
                <p className="mt-2 lg_mt-2">Eat mild foods in small portions</p>
              </div>
              <div className="text-center">
                <img src={bananas} style={{maxWidth:`100px`}} alt=""/>
                <p className="mt-2">Eat foods high in soluble fiber like bananas and potatoes</p>
              </div>
              <div className="text-center">
              <img src={fruitsVeggies} style={{maxWidth:`140px`}} alt=""/>
                <p className="mt-2">Don’t eat foods high in insoluble fiber like whole fruits or vegetables with skin or seeds</p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Managing diarrhea at home</h4>
            <p>Some tips to help manage your child’s diarrhea at home are:</p>
            <ul className="ul--dotted">
              <li>Keeping a record of your child’s toileting habits</li>
              <li>Monitoring your child’s skin for irritation</li>
              <li>Carrying an extra set of clothes and plastic bags for dirty laundry</li>
            </ul>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2" text="Please call your child’s healthcare team if your child has frequent loose stools or you are worried they may be dehydrated. "/>
      </Section>

      <Section>
        <SupportiveCareFooterLinks/>
      </Section>

      <InteriorPageFooter>

      <UpNextCard topic="Decreased Appetite and Nutrition" title="Decreased appetite and nutrition" lead="Learn about decreased appetite and proper nutrition during your child's treatment." url="/supportive-care/diet-nutrition/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default ConstipationDiarrhea
